import React from "react"
import { Link } from "gatsby"
import * as style from "./preview.module.sass"
import { GatsbyImage } from "gatsby-plugin-image"

const Preview = ({ name, source, description, slug }) => {
  return (
    <figure key={slug} className={style.figure}>
      <Link
        to={"/" + slug}
        // {slug} dann im index slug=project.blablabla.slug oder?
        className={style.preview}
      >
        <GatsbyImage className={style.img} alt={description} image={source} />
      </Link>
      <figcaption className={style.figcaption}>{name}</figcaption>
    </figure>
  )
}

export default Preview
